(function () {
	function i(e) {
		if (!window.frames[e]) {
			if (document.body && document.body.firstChild) {
				var t = document.body;
				var n = document.createElement('iframe');
				n.style.display = 'none';
				n.name = e;
				n.title = e;
				t.insertBefore(n, t.firstChild);
			} else {
				setTimeout(function () {
					i(e);
				}, 5);
			}
		}
	}
	function e(n, a, r, e, o) {
		function t(e, t, n) {
			if (typeof n !== 'function') {
				return;
			}
			if (!window[a]) {
				window[a] = [];
			}
			var i = false;
			if (o) {
				i = o(e, t, n);
			}
			if (!i) {
				window[a].push({ command: e, parameter: t, callback: n });
			}
		}
		t.stub = true;
		function i(i) {
			if (!window[n] || window[n].stub !== true) {
				return;
			}
			if (!i.data) {
				return;
			}
			var a = typeof i.data === 'string';
			var e;
			try {
				e = a ? JSON.parse(i.data) : i.data;
			} catch (t) {
				return;
			}
			if (e[r]) {
				var o = e[r];
				window[n](o.command, o.parameter, function (e, t) {
					var n = {};
					n.postMessageReturn = { returnValue: e, success: t, callId: o.callId };
					i.source.postMessage(a ? JSON.stringify(n) : n, '*');
				});
			}
		}
		if (typeof window[n] !== 'function') {
			window[n] = t;
			if (window.addEventListener) {
				window.addEventListener('message', i, false);
			} else {
				window.attachEvent('onmessage', i);
			}
		}
	}
	e('__uspapi', '__uspapiBuffer', '__uspapiCall', '__uspapiReturn');
	i('__uspapiLocator');
})();

window.gdprAppliesGlobally = true;
(function () {
	function n(e) {
		if (!window.frames[e]) {
			if (document.body && document.body.firstChild) {
				var t = document.body;
				var r = document.createElement('iframe');
				r.style.display = 'none';
				r.name = e;
				r.title = e;
				t.insertBefore(r, t.firstChild);
			} else {
				setTimeout(function () {
					n(e);
				}, 5);
			}
		}
	}
	function e(r, a, o, s, c) {
		function e(e, t, r, n) {
			if (typeof r !== 'function') {
				return;
			}
			if (!window[a]) {
				window[a] = [];
			}
			var i = false;
			if (c) {
				i = c(e, n, r);
			}
			if (!i) {
				window[a].push({ command: e, version: t, callback: r, parameter: n });
			}
		}
		e.stub = true;
		e.stubVersion = 2;
		function t(n) {
			if (!window[r] || window[r].stub !== true) {
				return;
			}
			if (!n.data) {
				return;
			}
			var i = typeof n.data === 'string';
			var e;
			try {
				e = i ? JSON.parse(n.data) : n.data;
			} catch (t) {
				return;
			}
			if (e[o]) {
				var a = e[o];
				window[r](
					a.command,
					a.version,
					function (e, t) {
						var r = {};
						r[s] = { returnValue: e, success: t, callId: a.callId };
						n.source.postMessage(i ? JSON.stringify(r) : r, '*');
					},
					a.parameter
				);
			}
		}
		if (typeof window[r] !== 'function') {
			window[r] = e;
			if (window.addEventListener) {
				window.addEventListener('message', t, false);
			} else {
				window.attachEvent('onmessage', t);
			}
		}
	}
	e('__uspapi', '__uspapiBuffer', '__uspapiCall', '__uspapiReturn');
	n('__uspapiLocator');
	e('__tcfapi', '__tcfapiBuffer', '__tcfapiCall', '__tcfapiReturn');
	n('__tcfapiLocator');
	(function (e) {
		var t = document.createElement('link');
		t.rel = 'preconnect';
		t.as = 'script';
		var r = document.createElement('link');
		r.rel = 'dns-prefetch';
		r.as = 'script';
		var n = document.createElement('link');
		n.rel = 'preload';
		n.as = 'script';
		var i = document.createElement('script');
		i.id = 'spcloader';
		i.type = 'text/javascript';
		i['async'] = true;
		i.charset = 'utf-8';
		var a = 'https://sdk.privacy-center.org/' + e + '/loader.js?target=' + document.location.hostname;
		if (window.didomiConfig && window.didomiConfig.user) {
			var o = window.didomiConfig.user;
			var s = o.country;
			var c = o.region;
			if (s) {
				a = a + '&country=' + s;
				if (c) {
					a = a + '&region=' + c;
				}
			}
		}
		t.href = 'https://sdk.privacy-center.org/';
		r.href = 'https://sdk.privacy-center.org/';
		n.href = a;
		i.src = a;
		var d = document.getElementsByTagName('script')[0];
		d.parentNode.insertBefore(t, d);
		d.parentNode.insertBefore(r, d);
		d.parentNode.insertBefore(n, d);
		d.parentNode.insertBefore(i, d);
	})('ac4d7cb7-7d50-4da7-9921-40a0397c630a');
})();
window.didomiConfig = {
	cookies: {
		storageSources: {
			cookies: true,
			localStorage: false
		}
	},
	notice: {
		daysBeforeShowingAgain: 182, // Number of days. Default is 0,
		closeOnClickBackdrop: false, // Behaviour for user clicks on the backdrop when the popup
		canCloseAndIgnore: false, // Disable user to close popup without consent
		learnMorePosition: 'before',
		denyOptions: {
			button: 'secondary'
		},
		content: {
			deny: {
				en: 'Reject all',
				de: 'Alle ablehnen.'
			},
			learnMore: {
				en: 'Manage cookies',
				de: 'Cookies verwalten.'
			},
			dismiss: {
				en: 'Accept all',
				de: 'Alle akzeptieren.'
			}
		}
	},
	preferences: {
		enableAllButtons: true
	}
};
