/**
 * Script for setting 'theme' attribute on the document before app bootstraps.
 * Should be included via. project.json 'scripts: []' configuration
 */
(function () {
	// Load theme, default to light
	let theme = window.localStorage.getItem('theme');
	if (!theme) {
		theme = 'light';
	}

	// Set theme attr on document
	window.document.documentElement.setAttribute('theme', theme);
})();
